<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    label: {
      type: String
    },
    chartData: {
      type: Array,
    },
    options: {
      type: Object,
    },
    charColor: {
      type: String,
    }
  },
  methods: {
    hexToRgbA(hexInput){
      
      var hex = String(hexInput).slice(0, -2)
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
          c= hex.substring(1).split('');
          if(c.length== 3){
              c= [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c= '0x'+c.join('');
          return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.5)';
      }
      throw new Error('Bad HEx');
    }
  },
  mounted () {
    const dates = this.chartData.map(d => d.date)
    const totals = this.chartData.map( d=> d.value)


    this.renderChart({
      labels: dates,
      datasets: [{
        label: this.label,
        data: totals,
        backgroundColor: this.hexToRgbA(this.charColor),
      }],
    },
    this.options
    )

  }
}
</script>
